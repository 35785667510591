<script>
import {PAGE_COUNT} from "@/helpers/datatable";

/**
 * 2022-09-28
 * 페이지 구현 by cto
 */
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    block: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      inputPage : 1,
      previewPage : 1,
      changeTimer: null
    }
  },
  mounted() {
    this.previewPage = this.page
    this.inputPage = this.page
  },
  computed: {
    pageCount() {
      return PAGE_COUNT
    },
    totalPage() {
      return Math.ceil(this.total / this.pageCount)
    },
    lastBlockCount() {
      if(this.totalPage === 0) return 1
      return (this.currentBlock >= this.totalBlockCount) ? this.totalPage - this.startPage : this.blockCount
    },
    totalBlockCount() {
      if(this.totalPage === 0) return 0
      return Math.ceil(this.totalPage / this.blockCount) - 1
    },
    currentBlock() {
      if(this.totalPage === 0) return 0
      return Math.floor((((this.previewPage > this.totalPage) ? this.totalPage : this.previewPage) - 1) / this.blockCount)
    },
    blockCount() {
      return this.block > 0 ? this.block : 10
    },
    startPage() {
      if(this.totalPage === 0) return 0
      return (this.currentBlock >= this.totalBlockCount) ? this.totalBlockCount * this.blockCount : this.currentBlock * this.blockCount
    },
  },
  methods: {
    getPage(index) {
      return this.startPage + index
    },
    getPrevBlock() {
      return this.startPage - this.blockCount + 1
    },
    getNextBlock() {
      return this.startPage + this.blockCount + 1
    },
    changePage(page) {
      // console.log(page)
      let trimPage = (page < 0)? 1 : parseInt(page)
      if(this.page !== trimPage) {
        this.previewPage = (!trimPage)? 1 : parseInt(trimPage)
        if(this.changeTimer != null) clearTimeout(this.changeTimer);

        this.changeTimer = setTimeout(() => {
          this.$emit('update:page', trimPage)
          this.$emit('changePage', trimPage)
        }, 300)
      }
    }
  },
  watch: {
    page(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.previewPage = newVal
        this.inputPage = newVal
      }
    },
    inputPage(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.changePage(newVal)
      }
    }
  }
};
</script>

<template>
  <ul v-if="total" class="pagination pagination-rounded justify-content-center mt-2">
    <li class="page-item" :class="{ disabled: currentBlock === 0 }">
      <a href="#" class="page-link" @click.prevent="previewPage = getPrevBlock()">
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>

    <li class="page-item" v-for="index in Math.min(lastBlockCount, pageCount)" :key="index">
      <a href="#" @click.prevent="changePage(getPage(index))" :class="{ active: page === getPage(index) }" class="page-link">{{getPage(index)}}</a>
    </li>

    <li class="page-item" :class="{ disabled: currentBlock === totalBlockCount }">
      <a href="#" class="page-link" @click.prevent="previewPage = getNextBlock()">
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
    <input type="number" class="form-control pagination-number d-none" v-model="inputPage" min="1" :max="totalPage"/>
  </ul>
</template>
