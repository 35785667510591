<script>
import store from "@/state/store";
import {menuItems} from "@/components/menu";

export default {
  components: {},
  mounted() {
    let menuId = this.$store ? store.state.layout.menuId : 100100;
    let topId = Math.floor(menuId / 10000) * 10000
    let parentId = Math.floor(menuId / 100) * 100

    let menu1 = menuItems.find(v => v.id == topId)
    let menu2 = null
    let menu3 = null

    if (parentId == menuId) {
      menu3 = menuItems.find(v => v.id == menuId)
    } else {
      menu2 = menuItems.find(v => v.id == parentId)
      if (menu2.subItems) {
        menu3 = menu2.subItems.find(v => v.id == menuId)
      }
    }

    this.title = this.$t(menu3 ? menu3.label : menu2.label)

    if (menu1) {
      this.items = this.items.concat([
          {
            text: this.$t(menu1.label),
            href: menu1.link
          }
      ])
    }
    if (menu2) {
      this.items = this.items.concat([
          {
            text: this.$t(menu2.label),
            href: menu2.link
          }
      ])
    }
    if (menu3) {
      this.items = this.items.concat([
        {
          text: this.$t(menu3.label),
          active: true
        }
      ])
    }

    document.title = menu3 ? this.$t(menu3.label) : "관리자";
  },
  data() {
    return {
      title: '',
      items: []
    };
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">{{ title }}</h4>
        <div class="page-title-right d-none d-md-block">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
