<script>
export default {
  name : 'Modal',
  props : ["modalData"],
  data() {
    return {
      data : this.modalData,
      showModal : false,
    }
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },
  }
}
</script>

<template>
  <b-modal
      v-model="showModal"
      id="modal-box"
      @show="openModal"
      @hidden="closeModal"
      centered
      title="상세정보"
      title-class="font-16 fw-bold"
      hide-footer
  >
    <div class="row mb-2 align-items-center">
      <div class="mb-2">
        <div class="mb-1 fw-semibold">회원명</div>
        <div class="form-control bg-light">{{ data.user }}</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">구분</div>
        <div class="form-control bg-light"><span :class="{'text-primary': data.point > 0, 'text-danger': data.point <= 0}">{{ data.title }}</span></div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">지급내용</div>
        <div class="form-control bg-light">{{ data.memo }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">지급포인트</div>
        <div class="form-control bg-light"><span :class="{'text-primary': data.point > 0, 'text-danger': data.point <= 0}">{{ data.point }}</span>&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">지급일시</div>
        <div class="form-control bg-light">{{ data.created_at }}&nbsp;</div>
      </div>
    </div>
  </b-modal>
</template>
